<template>
    <div>
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color" top>
            <v-icon>{{ snackbar.icon }}</v-icon>
            <span class="white--text ml-3">{{ snackbar.text }}</span>

            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" text v-bind="attrs" @click="snackbar.show = false">
                    Fechar
                </v-btn>
                <v-btn v-if="snackbar.confirmationBtn" color="secondary" text @click="snackbar.confirm()">
                    Confirmar
                </v-btn>
            </template>
        </v-snackbar>
        <v-list>
            <v-card class="mx-auto mb-5" outlined>
                <v-list-item outlined three-line>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ notification.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ notification.created_at_formated }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-body-1 my-5">
                            {{ notification.message }}
                        </v-list-item-subtitle>
                        <div class="text-overline mb-0">{{ notification.email }}</div>
                        <div class="text-overline mb-0" v-if="notification.contact">
                            <v-icon small class="mr-1" v-if="notification.is_whatsapp">mdi-whatsapp</v-icon>{{ notification.contact }}
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-list>
    </div>
</template>

<script>
export default {
    data: () => ({
        notification: {},
        snackbar: {
            color: "success",
            show: false,
            icon: "mdi-check-circle",
            confirmationBtn: false,
            confirm: Function,
            negate: Function,
            text: "",
            timeout: 2000,
        },
    }),
    methods: {
        getNotifications() {
            this.$api
                .get(`interests/${this.$route.params.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get("token")}`,
                    },
                })
                .then((res) => {
                    this.notification = res.data.data
                    this.updateNotifications(this.notification.id)
                })
                .catch((err) => {
                    this.snackbar = {
                        show: true,
                        color: 'error',
                        text: `Ops! Ocorreu um erro: ${err}`,
                        icon: 'mdi-alert-octagon',
                        timeout: 2000,
                    }
                })
        },
        updateNotifications(id) {
            this.$api
                .patch(`interests/${id}`,
                { viewed: true },
                {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get("token")}`,
                    },
                })
                .catch((err) => {
                    this.snackbar = {
                        show: true,
                        color: 'error',
                        text: `Ops! Ocorreu um erro: ${err}`,
                        icon: 'mdi-alert-octagon',
                        timeout: 2000,
                    }
                })
        },
    },
    mounted() {
        this.getNotifications()
    }
}
</script>